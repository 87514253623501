import React, { useEffect } from 'react'
import { Message } from 'intl'
import cx from 'classnames'

import { Icon } from 'components/dataDisplay'
import { ModalWithLayers } from 'components/feedback'
import { NavItem } from 'compositions/navigation'
import { type MobileNavBannerProps } from '../MobileNavBanner/MobileNavBanner'

import Section from '../Section/Section'


type NavRootItemProps = {
  data: Navigation.Item
  placement: MobileNavBannerProps['placement']
  closeNav: () => void
  onClick: () => void
}

const NavRootItem: React.FunctionComponent<NavRootItemProps> = (props) => {
  const { data: { id, title, label, content, exclusiveLabel, to, href, toTab, list, highlight, onClick: onItemClick, onDisplay, ref }, placement, onClick, closeNav } = props

  const navItemClassNames = cx(
    'block py-12 text-black',
    highlight && 'bg-light-beige -ml-40 -mr-16 pl-40 pr-16 font-bold'
  )

  if (list) {
    return (
      <>
        <ModalWithLayers.OpenButton layerId={id}>
          <button className="text-p1 py-12 text-black" type="button" data-testid={id}>
            <span className="flex items-center">
              <Message value={title} />
              <Icon className="ml-4" name="32/arrow-right" />
            </span>
          </button>
        </ModalWithLayers.OpenButton>
        <ModalWithLayers.Layer
          id={id}
          bgColor="white"
          backButtonStyle="action-button"
          pxMobile={40}
          scrollIntoView
        >
          {
            Boolean(onDisplay) && (
              <DisplayTracker onDisplay={onDisplay} />
            )
          }
          <Section
            id={id}
            list={list}
            title={title}
            placement={placement}
            isSubNav
            closeNav={closeNav}
          />
        </ModalWithLayers.Layer>
      </>
    )
  }

  return (
    <NavItem
      className={navItemClassNames}
      ref={ref}
      to={to}
      href={href}
      toTab={toTab}
      title={title}
      label={label}
      color={highlight ? 'gold-70' : 'black'}
      content={content}
      exclusiveLabel={exclusiveLabel}
      style="p1"
      data-testid={id}
      closeNav={closeNav}
      onClick={(event) => {
        onClick()
        if (typeof onItemClick === 'function') {
          onItemClick(event)
        }
      }}
    />
  )
}

const DisplayTracker: React.FC<{ onDisplay: () => void }> = ({ onDisplay }) => {
  useEffect(() => {
    onDisplay()
  }, [ onDisplay ])

  return null
}


export default NavRootItem
