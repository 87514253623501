import React, { useCallback } from 'react'
import links from 'links'
import { track } from 'analytics'
import { useReferralInvites } from 'modules/user'
import { Href } from 'components/navigation'
import { Icon } from 'components/dataDisplay'
import ReferralNavItem from 'compositions/Header/components/ReferralNavItem/ReferralNavItem'


const ReferralBlock = () => {
  const { stats: { available } } = useReferralInvites()

  const handleClick = useCallback(() => {
    track('Banner click', {
      placement: 'Header',
      content: 'Referral program',
      action: 'link',
      link: links.invite,
    })
  }, [])


  return Boolean(available) && (
    <Href
      to={links.invite}
      className="bg-gold-50 flex h-32 items-center justify-center gap-8 text-white"
      onClick={handleClick}
    >
      <ReferralNavItem spanClassName="text-black bg-white" style="p2" />
      <Icon name="other/long-arrow-right" color="white" />
    </Href>
  )
}

export default ReferralBlock
