import React from 'react'

import BannerView, { type BannerViewProps } from '../BannerView/BannerView'

import messages from './messages'

import appImage from './images/bg-app.jpg'


export type MobileAppBannerProps = {
  placement: BannerViewProps['placement']
}

const MobileAppBanner: React.FunctionComponent<MobileAppBannerProps> = ({ placement }) => {
  const burgerLink = 'https://app.adjust.com/btj5gqq'
  const profileLink = 'https://app.adjust.com/vdgft00'

  const toTab = placement === 'burger-nav' ? burgerLink : profileLink

  return (
    <BannerView
      supTitle={messages.supTitle}
      title={messages.title}
      text={messages.text}
      buttonTitle={messages.buttonTitle}
      image={appImage}
      toTab={toTab}
      placement={placement}
      trackParams={{
        content: 'App',
        link: toTab,
      }}
    />
  )
}


export default MobileAppBanner
