import { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import links from 'links'

import { constants } from 'helpers'
import { useFt } from 'hooks'

import { useCandleSubscription, useSamplesSubscription, useSubscription } from 'modules/subscription'

import { navMessages as messages } from 'compositions/navigation'


const useNav = (): Navigation.Item[] => {
  const isCandleSubscriptionEnabled = useFt(constants.features.candleSubscription)
  const isSamplesSubscriptionEnabled = useFeatureIsOn(constants.features.addonSamples)

  const { subscription } = useSubscription()

  const { candleSubscription } = useCandleSubscription()
  const { samplesSubscription } = useSamplesSubscription()

  const isUpgradeEntryPointsEnabled = subscription?.isUpgradableFromMonthlyPlan

  const isCandleSubscriptionVisible = Boolean(
    isCandleSubscriptionEnabled
    && candleSubscription?.isEnabled
    && !candleSubscription?.isSelected
  )
  const isSamplesSubscriptionVisible = Boolean(
    isSamplesSubscriptionEnabled
    && samplesSubscription?.isEnabled
    && !samplesSubscription?.isSelected
  )


  return useMemo(() => (
    [
      { id: 'perfumes', title: messages.perfumes, to: links.subscription.perfumes },
      { id: 'colognes', title: messages.colognes, to: links.subscription.colognes },
      { id: 'new-arrivals', title: messages.newArrivals, to: links.newArrivals.perfumes },
      isUpgradeEntryPointsEnabled && {
        id: 'upgrade',
        title: messages.upgrade2Scents,
        to: links.upgradeProduct,
      },
      !isUpgradeEntryPointsEnabled && !isSamplesSubscriptionVisible && isCandleSubscriptionVisible && {
        id: 'candleClub',
        title: messages.candleSubscription,
        to: links.candleSubscription,
      },
      !isUpgradeEntryPointsEnabled && isSamplesSubscriptionVisible && {
        id: 'samplesSubscription',
        title: messages.samplesSubscription,
        to: links.samplesSubscription,
      },
    ]
  ).filter(Boolean), [ isCandleSubscriptionVisible, isSamplesSubscriptionVisible, isUpgradeEntryPointsEnabled ])
}


export default useNav
