import React from 'react'
import { useAb } from 'hooks'
import { constants } from 'helpers'

import { Bone } from 'components/layout'

import { type BannerViewProps } from './components/BannerView/BannerView'
import MobileAppBanner from './components/MobileAppBanner/MobileAppBanner'
import SubscribeBanner from './components/SubscribeBanner/SubscribeBanner'
import StrapiMobileNavBanner from './components/StrapiMobileNavBanner/StrapiMobileNavBanner'
import SubscribeBannerLeadRedesignAb from './components/SubscribeBannerLeadRedesignAb/SubscribeBannerLeadRedesignAb'

import useMobileNavBanner from './util/useMobileNavBanner'


export type MobileNavBannerProps = {
  placement: BannerViewProps['placement']
}

const MobileNavBanner: React.FunctionComponent<MobileNavBannerProps> = ({ placement }) => {
  const { subscription, strapiBannerData, handleClick, isFetching } = useMobileNavBanner({ placement })
  const isLeadsTouchPointsRedesignEnabled = useAb(constants.abTests.leadsTouchPointsRedesign) === 'b'

  if (isFetching) {
    return <Bone pw={100} h={598} />
  }

  if (isLeadsTouchPointsRedesignEnabled && subscription?.hasNeverSubscribed) {
    return (
      <SubscribeBannerLeadRedesignAb />
    )
  }

  // For mobile version we need to make sure the height is set
  if (strapiBannerData?.height > 0) {
    return (
      <StrapiMobileNavBanner
        data={strapiBannerData}
        onClick={handleClick}
      />
    )
  }

  if (subscription.hasNeverSubscribed) {
    return (
      <SubscribeBanner placement={placement} />
    )
  }

  return (
    <MobileAppBanner placement={placement} />
  )
}


export default MobileNavBanner
