export default {
  title: {
    en: 'Unlock {discount} off your first month',
  },
  noDiscountTitle: {
    en: 'Unlock {fragrancesAmount}+ scents',
  },
  supTitle: {
    en: 'Welcome to Scentbird',
  },
  text: {
    en: `Say goodbye to barely-used full-sized bottles. With Scentbird, you'll get a 30-day supply of the fragrance of your choosing every month.`,
  },
}
