import React from 'react'
import links from 'links'
import { constants } from 'helpers'
import { useSubscribeOffer } from 'modules/subscription'

import { buttonMessages } from 'components/inputs'

import BannerView, { type BannerViewProps } from '../BannerView/BannerView'

import messages from './messages'
import appImage from './images/bg-subscribe.jpg'


export type SubscribeBannerProps = {
  placement: BannerViewProps['placement']
}

const SubscribeBanner: React.FunctionComponent<SubscribeBannerProps> = ({ placement }) => {
  const { offerType, eventDiscountText, discountText, couponCode, isFetching, handleSubscribeClick } = useSubscribeOffer()

  const title = offerType === 'discount'
    ? { ...messages.title, values: { discount: discountText } }
    : { ...messages.noDiscountTitle, values: { fragrancesAmount: constants.businessLogic.fragrancesAmount } }

  return (
    <BannerView
      supTitle={messages.supTitle}
      title={title}
      text={messages.text}
      buttonTitle={buttonMessages.subscribeNow}
      to={links.subscribe}
      image={appImage}
      isFetching={isFetching}
      placement={placement}
      trackParams={{
        content: 'Subscribe',
        action: 'link',
        link: links.subscribe,
        discount: eventDiscountText,
        coupon: couponCode,
      }}
      onClick={handleSubscribeClick}
    />
  )
}


export default SubscribeBanner
