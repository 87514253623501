import React, { useMemo, useState } from 'react'
import { useIntl } from 'intl'
import { track } from 'analytics'
import { useUser } from 'modules/user'

import { Avatar } from 'components/dataDisplay'
import { navMessages } from 'compositions/navigation'
import { useUserNav } from '../../util'

import NavModal from '../NavModal/NavModal'


const UserNav: React.FunctionComponent = () => {
  const [ isOpen, setOpen ] = useState(false)

  const { user } = useUser()
  const firstName = user?.firstName
  const lastName = user?.lastName

  const intl = useIntl()
  const buttonAriaLabel = intl.formatMessage(navMessages.aria.profileButton)

  const { open, close } = useMemo(() => ({
    open: () => {
      track('Profile icon click')
      setOpen(true)
    },
    close: () => setOpen(false),
  }), [])

  return (
    <>
      <button
        className="flex items-center justify-center text-white"
        type="button"
        aria-label={buttonAriaLabel}
        data-testid="profileMenuBtn"
        onClick={open}
      >
        <Avatar
          firstName={firstName}
          lastName={lastName}
          isActive={isOpen}
          size={32}
          data-testid="userAvatar"
        />
      </button>
      {
        isOpen && (
          <UserNavModal closeNav={close} />
        )
      }
    </>
  )
}

const UserNavModal: React.FC<{ closeNav: () => void }> = ({ closeNav }) => {
  const sections = useUserNav()

  return (
    <NavModal
      sections={sections}
      type="sidebar-right"
      placement="profile-nav"
      closeNav={closeNav}
    />
  )
}


export default UserNav
