import React from 'react'
import links from 'links'
import { useIntl } from 'intl'
import { track } from 'analytics'
import { useSubscribeOffer } from 'modules/subscription'
import { Button } from 'components/inputs'
import { Image, Text } from 'components/dataDisplay'
import { AspectContainer, WidthContainerCompensator } from 'components/layout'
import DiscountBadgeBase from 'compositions/DiscountBadgeBase/DiscountBadgeBase'

import image from './images/bgImage.jpg?src-loader'

import messages from './messages'


const SubscribeBannerLeadRedesignAb: React.FC = () => {
  const intl = useIntl()

  const {
    offerType,
    formattedPrice: price,
    formattedPriceWithDiscount: priceWithDiscount,
    discountText: discount,
    handleSubscribeClick,
  } = useSubscribeOffer()
  const { title, subtitle, description, buttonText, badge } = messages.offer[offerType ?? 'noOffer']

  const handleClick = () => {
    track('Banner click', {
      content: 'Subscribe',
      placement: 'Mobile navigation menu',
    })
    handleSubscribeClick()
  }

  return (
    <WidthContainerCompensator className="relative flex min-h-[600rem] flex-col items-stretch justify-start">
      {
        Boolean(badge) && (
          <DiscountBadgeBase
            className="z-1 font-tt-norms-pro absolute left-16 top-16 font-bold uppercase leading-[0]"
            containerClassName="border-gold-50"
            size={100}
          >
            <Text
              html
              style={null}
              color="gold-30"
              message={{ ...badge, values: { discount } }}
              withInnerLinksStyling={false}
            />
            <Text
              html
              style={null}
              color="white"
              className="mt-4 text-[8rem]/[8rem]"
              message={messages.freeCase}
              withInnerLinksStyling={false}
            />
          </DiscountBadgeBase>
        )
      }
      <AspectContainer aspect={image.aspect}>
        <Image
          fill
          remWidth={416}
          src={image.src}
          alt={intl.formatMessage(messages.imageAlt)}
        />
      </AspectContainer>
      <div className="flex flex-1 flex-col bg-black p-24 text-white">
        <Text color="gold-30" style="eye2" message={subtitle} />
        <Text html className="mt-8" style="h4" message={{ ...title, values: { discount } }} />
        <Text className="mt-8" style="p3" message={description} />
        <Button
          html
          fullWidthOnMobile
          style="gold-30"
          className="mt-16"
          size={56}
          title={{ ...buttonText, values: { price, priceWithDiscount } }}
          to={links.subscribe}
          onClick={handleClick}
        />
      </div>
    </WidthContainerCompensator>
  )
}


export default SubscribeBannerLeadRedesignAb
