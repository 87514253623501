import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { constants } from 'helpers'

import { track } from 'analytics'
import { Icon } from 'components/dataDisplay'
import { useMobileNav } from '../../util'

import NavModal from '../NavModal/NavModal'
import { useNavContext } from '../NavProvider/util'


import messages from './messages'
import s from './BurgerButton.module.css'


const BurgerButton: React.FunctionComponent = () => {
  const intl = useIntl()

  const { close, isOpen, open } = useNavContext()

  const handleOpen = () => {
    if (!isOpen) {
      track('Burger icon click')
    }

    open()
  }

  const label = intl.formatMessage(messages.label)

  return (
    <>
      <button
        className="size-s32 flex select-none items-center justify-center text-white"
        type="button"
        onClick={handleOpen}
        aria-label={label}
        data-testid="burger"
      >
        <Icon name="32/burger" className={cx(s.svg, isOpen && s.open)} isInline />
      </button>
      {
        isOpen && (
          <BurgerNavModal closeNav={close} />
        )
      }
    </>
  )
}

const BurgerNavModal: React.FC<{ closeNav: () => void }> = ({ closeNav }) => {
  const sections = useMobileNav()
  // do not delete this line, it's required for AA tests
  useFeatureIsOn(constants.features.aaBurgerIcon)

  return (
    <NavModal
      sections={sections}
      type="sidebar-left"
      placement="burger-nav"
      closeNav={closeNav}
    />
  )
}


export default BurgerButton
