export default {
  title: {
    en: 'Your scents, at your fingertips',
  },
  supTitle: {
    en: 'Download our app',
  },
  text: {
    en: 'Manage your upcoming shipments, rearrange your queue, and discover new favorites on our app.',
  },
  buttonTitle: {
    en: 'Download the scentbird app',
  },
}
