import React from 'react'

import { Image } from 'components/dataDisplay'
import { WidthContainerCompensator } from 'components/layout'
import { Href } from 'components/navigation'

import { useNavContext } from 'compositions/Header/view/Mobile/components/NavProvider/util'


type StrapiMobileNavBannerProps = {
  data: NavBannerModule.StrapiBannerData
  onClick?: () => void
}

const StrapiMobileNavBanner: React.FC<StrapiMobileNavBannerProps> = (props) => {
  const { data, onClick } = props

  const { close } = useNavContext()

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    }

    close()
  }

  const href = data.isExternalLink ? data.to : null

  return (
    <WidthContainerCompensator className="-mb-16">
      <Href to={data.to} href={href} onClick={handleClick} data-testid="strapiMobileNavBanner">
        <Image
          className="bg-gray-70 w-full object-cover object-center"
          src={data.bgImage}
          alt=""
          style={{ height: `${data.height}rem` }}
        />
      </Href>
    </WidthContainerCompensator>
  )
}

export default StrapiMobileNavBanner
