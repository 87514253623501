export default {
  imageAlt: {
    en: 'Luxury perfume samples from Scentbird, featuring Dolce & Gabbana, Versace, and Burberry fragrances, displayed on beige textured surfaces.',
  },
  freeCase: {
    en: '+ a free case',
  },
  offer: {
    noOffer: {
      badge: null,
      subtitle: {
        en: 'Welcome to scentbird',
      },
      title: {
        en: 'Complete your subscription',
      },
      description: {
        en: 'Say goodbye to barely-used full-sized bottles. With Scentbird, you’ll get a 30-day supply of the fragrance of your choosing every month.',
      },
      buttonText: {
        en: 'Check out now',
      },
    },
    discount: {
      badge: {
        en: '<span class="text-[24rem]/[24rem]">{discount}<br/>off</span>',
      },
      subtitle: {
        en: 'Welcome to scentbird',
      },
      title: {
        en: 'Unlock {discount} off your first month',
      },
      description: {
        en: 'Say goodbye to barely-used full-sized bottles. With Scentbird, you’ll get a 30-day supply of the fragrance of your choosing every month.',
      },
      buttonText: {
        en: 'Try first month for {priceWithDiscount} <s>{price}</s>',
      },
    },
    freeTrial: {
      badge: {
        en: '<span class="text-[20rem]/[20rem]">Free<br/>trial</span>',
      },
      subtitle: {
        en: 'Welcome to scentbird',
      },
      title: {
        en: 'complete your subscription and claim your free trial',
      },
      description: {
        en: 'Say goodbye to barely-used full-sized bottles. With Scentbird, you’ll get a 30-day supply of the fragrance of your choosing every month. You’ll only pay for shipping',
      },
      buttonText: {
        en: 'Check out now',
      },
    },
    freeProduct: {
      badge: {
        en: '<span class="text-[16rem]/[16rem]">Free<br/>perfume</span>',
      },
      subtitle: {
        en: 'Welcome to scentbird',
      },
      title: {
        en: 'Complete your subscription and claim your free perfume',
      },
      description: {
        en: 'Say goodbye to barely-used full-sized bottles. With Scentbird, you’ll get a 30-day supply of the fragrance of your choosing every month.',
      },
      buttonText: {
        en: 'Check out now',
      },
    },
    free2ndMonth: {
      badge: {
        en: '<span class="text-[14rem]/[14rem]">Second<br/>month<br/>free</span>',
      },
      subtitle: {
        en: 'Welcome to scentbird',
      },
      title: {
        en: 'Complete your subscription and get your second month for free',
      },
      description: {
        en: 'Say goodbye to barely-used full-sized bottles. With Scentbird, you’ll get a 30-day supply of the fragrance of your choosing every month.',
      },
      buttonText: {
        en: 'Check out now',
      },
    },
  },
}
