import { useMemo, useState } from 'react'


export type NavContextValue = ReturnType<typeof useNavContextValue>

const useNavContextValue = () => {
  const [ isOpen, setOpen ] = useState(false)

  const actions = useMemo(() => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false),
    }
  }, [ setOpen ])

  return {
    ...actions,
    isOpen,
  }
}

export default useNavContextValue
