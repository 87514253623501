import React from 'react'

import { SkipContent } from 'components/ada'

import LogoLink from 'compositions/LogoLink/LogoLink'
import { MainHeader, Search, Cart, UserNavWrapper, SubHeader, TopBarBanner, QueueCounter } from 'compositions/Header/components'

import { BurgerButton, NavProvider, ReferralBlock, SubNav, UserNav } from './components'


export type MobileProps = {
  withBanner?: boolean
  withSubHeader?: boolean
  withQueueCounter?: boolean
  loginWithBackRedirect?: boolean
  logoLink?: string
}

const Mobile: React.FunctionComponent<MobileProps> = (props) => {
  const { withBanner = true, withSubHeader = true, withQueueCounter, loginWithBackRedirect, logoLink } = props

  return (
    <NavProvider>
      <SkipContent contentId="main" />
      {
        withBanner && (
          <TopBarBanner />
        )
      }
      <MainHeader className="py-8">
        <div className="mr-12 flex flex-1">
          <BurgerButton />
          <LogoLink className="ml-12" color="white" to={logoLink} />
        </div>
        <div className="grid flex-1 grid-flow-col items-center justify-end gap-8">
          <Search />
          {
            withQueueCounter && (
              <QueueCounter />
            )
          }
          <Cart />
          <UserNavWrapper withRedirect={loginWithBackRedirect}>
            <UserNav />
          </UserNavWrapper>
        </div>
      </MainHeader>
      {
        withSubHeader && (
          <SubHeader>
            <SubNav />
          </SubHeader>
        )
      }
      <ReferralBlock />
    </NavProvider>
  )
}


export default Mobile
