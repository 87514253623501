import { useFt } from 'hooks'
import { constants } from 'helpers'

import { useSubscription } from 'modules/subscription'
import { useStrapiNavigationBanner } from 'modules/banners'


type UseMobileNavBannerProps = {
  placement: 'profile-nav' | 'burger-nav'
}

const useMobileNavBanner = ({ placement }: UseMobileNavBannerProps) => {
  const { subscription, isFetching: isSubscriptionFetching } = useSubscription()

  const isStrapiBannersEnabled = useFt(constants.features.strapiBanners)
  const { isFetching: isStrapiBannersFetching, bannerData, handleClick } = useStrapiNavigationBanner({
    placement,
    skip: !isStrapiBannersEnabled,
  })

  return {
    handleClick,
    subscription,
    strapiBannerData: bannerData,
    isFetching: isSubscriptionFetching || isStrapiBannersFetching,
  }
}

export default useMobileNavBanner
