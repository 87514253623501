import React from 'react'

import { useAb } from 'hooks'
import { constants } from 'helpers'
import links from 'links'
import { track } from 'analytics'
import { useUser } from 'modules/user'

import { ModalWithLayers, type ModalWithLayersProps } from 'components/feedback'
import { Button, buttonMessages } from 'components/inputs'
import Section from './components/Section/Section'
import MobileNavBanner, { type MobileNavBannerProps } from './components/MobileNavBanner/MobileNavBanner'


type NavContentProps = {
  sections: Navigation.HeaderMobileSection[]
  type: ModalWithLayersProps['type']
  placement: MobileNavBannerProps['placement']
  closeNav: () => void
}

const NavModal: React.FunctionComponent<NavContentProps> = ({ sections, type, placement, closeNav }) => {
  const { user, isLoggedIn } = useUser()
  const isLead = !isLoggedIn || user?.analyticsMetadata?.subscriptionStatus === 'LEAD'

  const isTryNowLinkAbEnabled = useAb(constants.abTests.headerTryNowLink) === 'b'

  return (
    <ModalWithLayers
      bgColor="white"
      type={type}
      withCloseButton={false}
      underMobileHeader
      overlayClosable
      closeModal={closeNav}
      data-testid="mobile-nav-modal"
    >
      {
        isTryNowLinkAbEnabled && isLead && placement === 'burger-nav' && (
          <div className="mb-56 px-8">
            <Button
              className="border"
              message={isLoggedIn ? buttonMessages.tryNow : buttonMessages.login}
              style="secondary"
              size={56}
              fullWidth
              to={isLoggedIn ? links.subscribe : `${links.login}?redirect=${links.subscribe}`}
              onClick={() => {
                if (isLoggedIn) {
                  track('Try now button click', {
                    placement,
                  })
                }
              }}
            />
          </div>
        )
      }
      <nav className="pl-24" aria-label="Main">
        {
          sections.map(({ id, list, title, onDisplay }) => (
            <Section
              key={id}
              id={id}
              title={title}
              list={list}
              closeNav={closeNav}
              placement={placement}
              onDisplay={onDisplay}
            />
          ))
        }
      </nav>
      <MobileNavBanner placement={placement} />
    </ModalWithLayers>
  )
}

export default NavModal
