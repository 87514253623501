import React from 'react'

import { NavContext, useNavContextValue } from './util'


type NavProviderProps = {
  children: React.ReactNode
}

const NavProvider: React.FunctionComponent<NavProviderProps> = ({ children }) => {
  const value = useNavContextValue()

  return (
    <NavContext.Provider value={value}>
      {children}
    </NavContext.Provider>
  )
}

export default NavProvider
