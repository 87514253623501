import React from 'react'
import { useVisibilityTrigger } from 'intersection-observer'
import { track } from 'analytics'
import { sanitizeForAnalytics } from 'helpers'
import { Text } from 'components/dataDisplay'
import NavRootItem from '../NavRootItem/NavRootItem'
import { type MobileNavBannerProps } from '../MobileNavBanner/MobileNavBanner'


type SectionProps = Navigation.HeaderMobileSection & {
  placement: MobileNavBannerProps['placement']
  isSubNav?: boolean
  closeNav?: () => void
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
  const { id, list, title: sectionTitle, isSubNav, placement, closeNav, onDisplay } = props

  const blockName = typeof sectionTitle === 'string' ? sectionTitle : sectionTitle.en
  const ref = useVisibilityTrigger(() => {
    if (typeof onDisplay === 'function') {
      onDisplay()
    }
  })

  return (
    <div key={id} className="mb-56" ref={ref}>
      <Text
        className="my-8"
        message={sectionTitle}
        tag="h3"
        style={isSubNav ? 'sh5' : 'h8'}
        color={isSubNav ? 'black' : 'gold-50'}
        data-testid={id}
      />
      <ul className={isSubNav ? 'pl-20' : null}>
        {
          list.map((item) => (
            <li key={item.id}>
              <NavRootItem
                data={item}
                placement={placement}
                closeNav={closeNav}
                onClick={() => {
                  const event = placement === 'burger-nav' ? 'Burger menu click' : 'Profile menu click'
                  const itemTitle = typeof item.title === 'string' ? item.title : item.title.en

                  track(event, {
                    blockName,
                    title: sanitizeForAnalytics(isSubNav ? `${blockName}/${itemTitle}` : itemTitle),
                    level: isSubNav ? 2 : 1,
                    link: item.to || item.toTab,
                  })
                }}
              />
            </li>
          ))
        }
      </ul>
    </div>
  )
}


export default Section
