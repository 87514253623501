import React from 'react'


const SubHeader: React.CFC = (props) => {
  const { children } = props

  return (
    <div className="bg-gold-30 flex items-center justify-center">
      {children}
    </div>
  )
}


export default SubHeader
