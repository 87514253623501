import React from 'react'

import { track } from 'analytics'

import { Image, Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import { AspectContainer, WidthContainerCompensator } from 'components/layout'

import { useNavContext } from 'compositions/Header/view/Mobile/components/NavProvider/util'


export type BannerViewProps = {
  supTitle: Intl.Message
  title: Intl.Message
  text: Intl.Message
  buttonTitle: Intl.Message
  image: string
  isFetching?: boolean
  isSubmitting?: boolean
  placement: 'burger-nav' | 'profile-nav'
  trackParams: Omit<Analytics.Events['Banner click'], 'placement'>
} & AtLeastOne<{
  toTab: string
  to: string
  onClick: () => void
}>

const BannerView: React.FunctionComponent<BannerViewProps> = (props) => {
  const { supTitle, title, text, buttonTitle, image, toTab, to, isFetching, isSubmitting, placement, trackParams, onClick } = props

  const { close } = useNavContext()

  const handleClick = () => {
    track('Banner click', {
      ...trackParams,
      placement: placement === 'profile-nav' ? 'Profile menu' : 'Burger menu',
    })

    if (typeof onClick === 'function') {
      onClick()
    }

    close()
  }

  return (
    <WidthContainerCompensator className="-mb-16 bg-black">
      <AspectContainer aspect={416 / 320}>
        <Image
          fill
          src={image}
          alt=""
          sizes="100vw"
        />
      </AspectContainer>
      <div className="p-24 pb-32">
        <Text
          message={supTitle}
          color="gold-30"
          style="eye2"
        />
        <Text
          className="mt-8"
          message={title}
          color="white"
          style="h4"
        />
        <Text
          className="mt-8"
          message={text}
          color="white"
          style="p3"
        />
        <Button
          className="mt-16"
          title={buttonTitle}
          style="gold-30"
          toTab={toTab}
          to={to}
          size={56}
          fullWidth
          disabled={isFetching}
          loading={isSubmitting}
          onClick={handleClick}
        />
      </div>
    </WidthContainerCompensator>
  )
}


export default BannerView
