import React, { memo } from 'react'
import { track } from 'analytics'
import { sanitizeForAnalytics } from 'helpers'

import { NavItem, NavList } from 'compositions/navigation'

import useNav from './useNav'


const SubNav: React.FunctionComponent = () => {
  const items = useNav()

  return (
    <NavList className="py-4" listClassName="grid grid-flow-col items-center gap-12" aria-label="Header fast access">
      {
        items.map((item) => {
          const { id, title, to, ref } = item

          const handleClick = () => {
            track('Mobile fast navigation click', {
              title: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
              link: to,
            })
          }

          return (
            <li key={id}>
              <NavItem style="p2" title={title} to={to} onClick={handleClick} ref={ref} />
            </li>
          )
        })
      }
    </NavList>
  )
}


export default memo(SubNav)
